//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


const YOUTUBE_EMBED_URL = 'https://www.youtube.com/embed/'
const YOUTUBE_EMBED_NOCOOKIE_URL = 'https://www.youtube-nocookie.com/embed/'

const DEFAULT_ALT_ATTRIBUTE = 'Video thumbnail';
const DEFAULT_BUTTON_LABEL = 'Play video';
const DEFAULT_ASPECT_RATIO = '16:9';
const PREVIEW_IMAGE_SIZES = [
    'default',
    'mqdefault',
    'sddefault',
    'hqdefault',
    'maxresdefault',
];
const DEFAULT_PREVIEW_IMAGE_SIZE = PREVIEW_IMAGE_SIZES[4];
const DEFAULT_IFRAME_ATTRIBUTES = {
    allowfullscreen: true,
    frameborder: 0,
    allow: 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture',
};

export default {

    name: 'CustomYoutube',

    props: {
        id: {
            type: String
        },
        pClass: {
            type: String
        },
        src: {
            type: String,
            required: true,
        },
        alt: {
            type: String,
            default: DEFAULT_ALT_ATTRIBUTE,
        },
        buttonLabel: {
            type: String,
            default: DEFAULT_BUTTON_LABEL,
        },
        aspectRatio: {
            type: String,
            default: DEFAULT_ASPECT_RATIO,
        },
        previewImageSize: {
            type: String,
            default: DEFAULT_PREVIEW_IMAGE_SIZE,
        },
        thumbnail: {
            type: Object,
            validator: (val) => 'jpg' in val && 'webp' in val,
        },
        iframeAttributes: {
            type: Object,
        },
        webp: {
            type: Boolean,
            default: true,
        },
        autoplay: {
            type: Boolean,
            default: false,
        },
        thumbnailListeners: {
            type: Object,
        },
        enablejsapi: {
            type: Boolean,
            default: false,
        },
        playerOptions: {
            type: Object,
            default: () => ({}),
        },
        injectPlayerScript: {
            type: Boolean,
            default: false,
        },
        parameters: {
            type: Object,
            default: () => ({}),
        },
        initInstance: {
            type: Boolean,
            default: false
        },
        nocookie: {
            type: Boolean,
            default: true
        }
    },

    components: {
        LazyYoutubeVideo: () => import('./vue-lazy-youtube-video-custom') ,
    },

    data() {
        return {
            isVisible: false,
        }
    },

/*    mounted() {

        window.onYouTubeIframeAPIReady = function () {
            console.log('onYouTubeIframeAPIReady')
        };

    },*/


    methods: {

        visibilityChanged (isVisible, entry) {
            if(isVisible) {
                this.isVisible = isVisible
            }

        },

        onLoadIframe (iframe) {
            this.initMatomoMedia()
        },

        onInitPlayer (payload) {

        },

        initMatomoMedia() {

            var updatedElement = null

            if(this.id) {
                updatedElement = document.getElementById(this.id);
            }

            /*
            if (window.Matomo) {

                if(this.$config.debug === true) {
                    window.Matomo.MediaAnalytics.enableDebugMode()
                }
                window.Matomo.MediaAnalytics.scanForMedia(updatedElement)

            } else {

                window.matomoMediaAnalyticsAsyncInit = function () {

                    if(this.$config.debug === true) {
                        window.Matomo.MediaAnalytics.enableDebugMode()
                    }
                    window.Matomo.MediaAnalytics.scanForMedia(updatedElement)

                }

            } */

        },


    },

    computed: {

        getSrc() {

            const youtubeId = this.$helper.youtubeIdFromUrl(this.src)
            var url

            if(this.nocookie === true) {
                url = YOUTUBE_EMBED_NOCOOKIE_URL
            } else {
                url = YOUTUBE_EMBED_URL
            }

            return url + youtubeId

        }

    }

}

